import './index.css'
import { useState, useEffect } from 'react'
import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { Session } from '@supabase/supabase-js'

const supabase = createClient(
  'https://dyyyiaemcpmzynhvpevw.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImR5eXlpYWVtY3BtenluaHZwZXZ3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzExMzMwMzUsImV4cCI6MjA0NjcwOTAzNX0.fQjyuveRyhnRDjI8MJuFa6gh1sWj7M9LeN9WMDub1c0'
)

function App() {
  const [session, setSession] = useState<Session | null>(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (_event, session) => {
      setSession(session)

      if (session) {
        // Insert the user into the users table
        const { error } = await supabase
          .from('users')
          .insert({
            id: session.user.id,
            email: session.user.email,
            is_paying: false
          })

        if (error) {
          console.error('Error inserting user:', error)
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [])

  if (!session) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '20px'
      }}>
        <div style={{
          width: '100%',
          maxWidth: '420px',
          padding: '20px',
          background: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
        }}>
          <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} />
        </div>
      </div>
    )
  }
  else {
    return (<div>Logged in!</div>)
  }
}

export default App;
